<template>
	<div class="container-fluid add-form-list">
		<div class="row">
			<div class="col-sm-12">
				<div class="card">
					<div class="card-header d-flex justify-content-between">
						<div class="header-title">
							<h4 class="card-title">재고등록</h4>
						</div>					
					</div>
					<div class="card-body">

						<tab-nav :tabs="true" id="myTab-1">
							<tab-nav-items :active="true" id="home-tab" ariaControls="home" role="tab" :ariaSelected="true" title="재고등록/등록배치" />
							<tab-nav-items :active="false" id="change-tab" ariaControls="change" role="tab" :ariaSelected="false" title="재고배치/위치변경" />
							<!-- <tab-nav-items :active="false" id="profile-tab" ariaControls="profile" role="tab" :ariaSelected="false" title="구매재고등록" />
							<tab-nav-items :active="false" id="contact-tab" ariaControls="contact" role="tab" :ariaSelected="false" title="회송재고등록" /> -->
							<tab-nav-items :active="false" id="many-tab" ariaControls="many" role="tab" :ariaSelected="false" title="재고대량등록" />
						</tab-nav>
						<tab-content id="myTabContent">
							<tab-content-item :active="true" id="home" aria-labelled-by="home-tab">
								<p>바코드 작업이 완료된 실재고를 등록해 주세요</p>
								<form @submit.prevent="submit">
									<div class="row">
										<div class="col-md-3">
											<div class="form-group">
												<label>Barcode 조회</label>
												<input ref="findbarcode" type="text" class="form-control" placeholder="Barcode 조회" @keyup.enter="findbarcodeinfo($event)" v-model="barcodevalue">
											</div>
										</div>
										<div class="col-md-3">											
											<div class="form-group">
												<label>SKU ID 조회</label>
												<input type="text" class="form-control" placeholder="SKU ID 조회" @keyup.enter="findskuinfo($event)" v-model="skuidvalue">
											</div>
										</div>

										<div class="col-md-12">

											<div class="custom-control custom-radio custom-control-inline">
												<input type="radio" id="customRadio8" name="customRadio-1" class="custom-control-input" v-model="selected" value="reg" @change="showbox">
												<label class="custom-control-label" for="customRadio8" v-b-tooltip.top="'발주서와 관계없이 재고로 등록'">재고 등록</label>
											</div>
											<div class="custom-control custom-radio custom-control-inline">
												<input type="radio" id="customRadio7" name="customRadio-1" class="custom-control-input" v-model="selected" value="default" @change="showbox">
												<label class="custom-control-label" for="customRadio7" v-b-tooltip.top="'발주서를 선택한 후 배치 등록'">발주 배치</label>
											</div>		
								
										</div>	
										
										<div class="col-md-9 mt-3" v-if="showfindlist">	
											<div class="row">
												<div class="col-9">
													<card style="padding:10px;">										
														<div class="d-flex align-items-center" style="margin:auto;padding:0px">
															<img :src="findlists[0].발주세부내역.IMGURL" class="img-fluid rounded avatar-80 mr-3" alt="image">
															<div>
																{{findlists[0].발주세부내역.상품명}}
																<p class="mb-0">바코드 : {{findlists[0].발주세부내역.바코드}}</p>
																<p class="mb-0">SKU ID : {{findlists[0].발주세부내역.SKUID}}</p>
															</div>
														</div>
													</card>
												</div>
												<div class="col-3 text-center">
													<card style="padding:10px;">										
															<p class="mb-0">신규배치박스번호</p>
															<p class="mb-0" style="font-size:200%">{{신규박스번호}}</p>
													</card>
												</div>
											</div>
										</div>				
										<div class="table-responsive col-md-12" style="text-align:center;vertical-align:middle;" v-if="showfindlist">
											<table id="datatable" class="table data-table table-striped dataTable">
											<thead style="font-size:80%;">
												<tr class="ligth ligth-data">
													

													<th style="font-size:115%;vertical-align: middle;">발주센터</th> 
													<th style="font-size:115%;vertical-align: middle;">입고예정일</th>
													<th style="font-size:115%;vertical-align: middle;">현재배치수량</th>
													<th style="font-size:115%;vertical-align: middle;">추가배치수량</th>
													<th style="font-size:115%;vertical-align: middle;">배치박스</th>
													<th style="font-size:115%;vertical-align: middle;">필요수량</th>
													<th style="font-size:115%;vertical-align: middle;">발주번호</th>													
													
												</tr>
											</thead>
											<tbody style="font-size:100%;">
												<tr v-for="a,i1 in findlists" :key="i1">

													<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{a.발주센터}}<span class="ml-2" @click="print('printMe2' + i1)"><i class="ri-printer-line" style="font-size:200%"></i></span>
														<div class="d-none text-center" style="text-align:center;" :id="'printMe2' + i1">
															<div class="row mb-0" style="text-align:center;">{{$store.state.user.companyid}}</div>
															<div class="row mb-0" style="text-align:center;">{{a.발주센터}}</div>
															<div class="row mb-0" style="text-align:center;">{{a.입고예정일}}</div>
															<div class="row mb-0" style="text-align:center;font-size:200%">{{a.배치박스번호}}</div>
															<div class="row mb-0" style="text-align:center;">
															<barcode :value="$store.state.user.companyid.slice(0,2)+'kr'+a.입고예정일.replaceAll('-','').slice(4)+a.배치박스번호" :tag="bctag" width="2" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
															</div>
														</div>
													</td>

													<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{a.입고예정일}}</td>
													<td style="max-width:50px;overflow:hidden;text-overflow: ellipsis;">{{a.발주세부내역.배치수량}}</td>
													<td style="max-width:50px;overflow:hidden;text-overflow: ellipsis;">
														<input style="font-size:200%;text-align:center" :min="0" :max="a.발주세부내역.필요수량" type="number" class="form-control" placeholder="배치수량" aria-label="" v-model="a.추가배치수량">
													</td>
													<td style="max-width:100px;overflow:hidden;text-overflow: ellipsis;">

														<div style="display: table; margin-left: auto; margin-right: auto; display: inline-block;">
														<div class="input-group position-relative">
															<input style="font-size:200%;text-align:center" :min="0" type="number" class="form-control h-100" placeholder="박스번호" aria-label="" v-model="a.배치박스번호">
															<span v-if="a.newbox" class="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-secondary p-2">new</span>
														</div>
														<div class="input-group mt-2">
															<input style="text-align:center" :min="0" type="text" class="form-control" placeholder="박스위치" aria-label="" v-model="a.배치파렛트번호">
															<div class="input-group-append">
															</div>
														</div>
														</div>
														
													</td>
													<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{a.발주세부내역.필요수량}}</td>
													<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{a.발주번호}}</td>													
													
												</tr>
											</tbody>
											</table>
										</div>
										<div class="col-md-12" v-if="showfindlist">
											<button class="btn btn-primary" type="button" @click="savearrange()">배치 저장</button>
										</div>
									</div>
									

									<!-- 발주가 없을 경우 재고로 등록 -->
									<div class="row mt-3" v-if="showstockbox">
										<div class="col-md-3">	
											<div class="input-group mb-4">
												<input type="number" class="form-control" placeholder="박스번호" :min="0" aria-label="" @change="boxchangeref" v-model="stockboxnum" style="font-size:200%;text-align:center;">
												<div class="input-group-append">
												<b-dropdown class="my-class" variant="primary" type="button" text="리스트">
													<b-dropdown-item @click="boxnumberchange(b)" v-for="b,j in stockboxnumbers" :key="j">{{b}}</b-dropdown-item>
												</b-dropdown>
												</div>
												<span class="ml-2 text-center align-middle" @click="print('printMe3')"><i class="ri-printer-line" style="font-size:200%"></i></span>
												<div class="d-none text-center" style="text-align:center;" id="printMe3">
													<div class="row mb-0" style="text-align:center;">{{$store.state.user.companyid}}</div>
													<div class="row mb-0" style="text-align:center;">재고</div>
													<div class="row mb-0" style="text-align:center;font-size:200%">{{stockboxnum}}</div>
													<div class="row mb-0" style="text-align:center;">
														<barcode :value="$store.state.user.companyid.slice(0,2)+'kr'+'s'+stockboxnum" :tag="bctag" width="2" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-3">	
											<div class="input-group mb-4">
												<input type="text" class="form-control" placeholder="박스위치" aria-label="" @keyup.enter="stocklocation($event)" v-model="stockpalletnum"  style="text-align:center;">
												<div class="input-group-append">
												<button class="btn btn-secondary" type="button" @click="locationchange">지정</button>
												</div>
											</div>
										</div>
										<div class="col-md-3">	
											<div class="custom-control custom-switch custom-control-inline">
												<input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checkpo">
												<label class="custom-control-label" for="customSwitch2">발주내역 확인등록</label>
											</div>
										</div>	
										<div class="col-md-12">		
											<div class="form-group">	
												<input type="text" ref="barcoderegstock" class="form-control" placeholder="바코드(스캐너)로 등록" @keyup.enter="barcoderegstock($event)" v-model="regstock">
											</div>
										</div>
										<div class="col-md-6">
											<button class="btn btn-primary" type="button" @click="savestock()">재고박스 업데이트</button>
										</div>
										<div class="col-md-6 float-right">
											<div class="float-right"><h3>Total : {{totalqty}} 개</h3></div>
										</div>
										<div class="table-responsive col-md-12" style="font-size:80%;text-align:center;width:100%">
											<table id="datatable" class="table data-table table-striped dataTable" >
												<thead>
													<tr class="ligth ligth-data">
														
														<th style="font-size:115%;vertical-align: middle;">상품정보</th>
														<th style="font-size:115%;vertical-align: middle;">수량</th>
														<th>삭제</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="stockboxlist,i2 in stockboxlists" :key="i2">
														<td style="min-width:300px;text-align:left;">
															<div class="d-flex align-items-center">
																<img :src="stockboxlist.IMGURL" class="img-fluid rounded avatar-80 mr-3" alt="image">
																<div>
																	{{stockboxlist.상품명}}
																	<p class="mb-0">바코드 : {{stockboxlist.바코드}}</p>
																	<p class="mb-0">SKU ID : {{stockboxlist.SKUID}}</p>
																</div>
															</div>
														</td>
														<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;"><b-form-input :min="0" type="number" v-model.number="stockboxlist.수량" @change="stockchange = true" :style="stockstyle(i2)"/></td>
														<td>
															<a class="badge bg-warning" data-toggle="tooltip" v-b-tooltip.top title="Delete" href="javascript:void(0)" @click="deletelist(stockboxlist)">
																<i class="ri-delete-bin-line mr-0"></i>
															</a>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										
									</div>
																	
									
									<!-- <button type="submit" class="btn btn-primary mr-2 mt-4">Add Sale</button>
									<button type="reset" class="btn btn-danger mt-4">Reset</button> -->
								</form>
							</tab-content-item>





							<tab-content-item :active="false" id="change" aria-labelled-by="change-tab">
								<p>이미 등록된 재고의 박스/수량/위치를 변경하거나, 발주 배치를 진행해 주세요.</p>
								<b-card-group deck>
									<b-card>
										<div class="row">
											<div class="col-md-3">
												<input type="text" class="form-control" placeholder="발주배치박스번호" v-model="cabox">
											</div>
											<div class="col-md-3">
												<input type="text" class="form-control" placeholder="재고박스번호" v-model="cbox">
											</div>
											<div class="col-md-3">
												<input type="text" class="form-control" placeholder="Barcode" v-model="cbar">
											</div>
											<div class="col-md-3">
												<input type="text" class="form-control" placeholder="SKU ID" v-model="csku">
											</div>
											<div class="col-md-12 mt-2">

												<div class="custom-control custom-radio custom-control-inline h-100">
													<input type="radio" id="customRadio11" name="customRadio-2" class="custom-control-input" v-model="selected2" value="notastock">
													<label class="custom-control-label" for="customRadio11" v-b-tooltip.top="'발주가 있으나 미배치된 재고'">배치필요</label>
												</div>
												<div class="custom-control custom-radio custom-control-inline h-100">
													<input type="radio" id="customRadio13" name="customRadio-2" class="custom-control-input" v-model="selected2" value="stock">
													<label class="custom-control-label" for="customRadio13" v-b-tooltip.top="'발주가 있으나 미배치된 재고'">재고</label>
												</div>
												<div class="custom-control custom-radio custom-control-inline h-100">
													<input type="radio" id="customRadio14" name="customRadio-2" class="custom-control-input" v-model="selected2" value="astock">
													<label class="custom-control-label" for="customRadio14" v-b-tooltip.top="'발주가 있으나 미배치된 재고'">배치재고</label>
												</div>
												<div class="custom-control custom-radio custom-control-inline h-100">
													<input type="radio" id="customRadio12" name="customRadio-2" class="custom-control-input" v-model="selected2" value="stocktotal">
													<label class="custom-control-label" for="customRadio12" v-b-tooltip.top="'전체재고'">전체재고</label>
												</div>											
												<button type="button" class="btn btn-primary h-100" @click="search">검색</button>
											</div>	
										
										
										
										
											<div class="col-md-12 mt-4" v-if="showlist" style="font-size:80%;">
												<div class="table-responsive rounded mb-3">
													<tableList>
														<template v-slot:header>
															<tr class="ligth ligth-data">
																<th>
																	<div class="checkbox d-inline-block">
																		<input type="checkbox" class="checkbox-input" id="checkbox1" @change="checkallbox" v-model="checkbox1">
																		<label for="checkbox1" class="mb-0"></label>
																	</div>
																</th>
																<th>구분</th>
																<th>박스</th>
																<th>등록일시</th>
																<th>상품정보</th>
																<th>박스바코드</th>
																<th>박스위치</th>
																<th>수량</th>용
																<th>배치필요수량</th>
																<th>발주정보</th>
															</tr>
														</template>
														<template v-slot:body>
															<tr v-for="(stocklist,index) in stocklists" :key="index">
																<td>
																	<div class="checkbox d-inline-block">
																		<input type="checkbox" class="checkbox-input" id="checkbox2" @change="checkboxlistfunc" v-model="checkboxlist[index]">
																		<label for="checkbox2" class="mb-0"></label>
																	</div>
																</td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)"><div v-bind:class="badgecolor(stocklist,stocklist.stockinfo)">{{changestockinfo(stocklist,stocklist.stockinfo)}}</div></td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)">{{changeboxinfo(stocklist.stockinfo,stocklist.재고박스번호,stocklist.배치박스번호)}}</td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)">{{stocklist.입고일시}}</td>
																<td v-b-modal="modalID1(index)" style="min-width:200px;" @click="modaldata(stocklist)">
																	<div class="d-flex align-items-center">
																			<img :src="stocklist.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
																			<div>
																				{{stocklist.상품명}}
																				<p class="mb-0" style="font-size:120%"><small>{{stocklist.바코드}}</small></p>
																				<p class="mb-0" style="font-size:120%"><small>{{stocklist.SKUID}}</small></p>
																			</div>
																	</div>
																</td>  
																<td>{{stocklist.박스바코드}}</td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)">{{changepalletinfo(stocklist.stockinfo,stocklist.재고파렛트번호,stocklist.배치파렛트번호)}}</td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)">{{stocklist.수량}}</td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)">{{stocklist.배치필요수량 ? stocklist.배치필요수량 : 0}}</td>
																<td v-b-modal="modalID1(index)" @click="modaldata(stocklist)">
																	<div class="d-flex align-items-center">
																		<div v-if="stocklist.배치발주번호.length > 0">
																			<p class="mb-0" style="font-size:120%"><small>{{stocklist.배치발주센터}}</small></p>
																			<p class="mb-0" style="font-size:120%"><small>{{stocklist.배치입고예정일}}</small></p>
																			<p class="mb-0" style="font-size:120%"><small>{{stocklist.배치발주번호}}</small></p>
																		</div>
																		<div v-else>
																			<p class="mb-0" style="font-size:120%;color:red;"><small>{{stocklist.배치필요내역[0].배치필요발주센터}}</small></p>
																			<p class="mb-0" style="font-size:120%;color:red;"><small>{{stocklist.배치필요내역[0].배치필요입고예정일}}</small></p>
																			<p class="mb-0" style="font-size:120%;color:red;"><small>{{stocklist.배치필요내역[0].배치필요발주번호}}</small></p>
																		</div>
																	</div>
																</td>


																		<b-modal ref="bvModal" :id="'modal1'+index" scrollable title="배치필요내역" ok-title="저장" cancel-title="취소" class="detail-modal" @ok="handleOk" @cancel="cancel">
																			<div class="table-responsive">	
																				<table id="datatable" class="table data-table table-striped dataTable" >
																				<thead>
																					<tr class="ligth" style="font-size:80%;">
																						<th>
																							<div class="checkbox d-inline-block">
																								<input type="checkbox" class="checkbox-input" id="modalcheck1" @change="checkall" v-model="modalcheck1">
																								<label for="modalcheck1" class="mb-0"></label>
																							</div>
																						</th>
																						<th>상품정보</th>
																						<th>발주번호</th>
																						<th>발주센터</th>
																						<th>입고예정일</th>
																						<th>박스번호</th>
																						<th>박스위치</th>
																						<!-- <th>Action</th> -->
																					</tr>
																				</thead>
																				<tbody>
																					<tr v-for="c,i3 in stockdetail" :key="i3" role="row" style="font-size:80%;">
																						<td>
																							<div class="checkbox d-inline-block">
																								<input type="checkbox" class="checkbox-input" id="modalcheck2" @change="check" v-model="c.checked">
																								<label for="modalcheck2" class="mb-0"></label>
																							</div>
																						</td>
																						<td style="min-width:200px;">
																							<div class="d-flex align-items-center">
																									<img :src="c.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
																									<div>
																										{{c.상품명}}
																										<p class="mb-0" style="font-size:120%"><small>{{c.바코드}}</small></p>
																										<p class="mb-0" style="font-size:120%"><small>{{c.SKUID}}</small></p>
																									</div>
																							</div>
																						</td>
																						<td>
																							<p v-if="c.배치발주번호">{{c.배치발주번호}}</p>
																							<p v-else style="color:red;">{{c.배치필요발주번호}}</p>
																						</td>
																						<td>
																							<p v-if="c.배치발주번호">{{c.배치발주센터}}</p>
																							<p v-else style="color:red;">{{c.배치필요발주센터}}</p>
																						</td>
																						<td>
																							<p v-if="c.배치발주번호">{{c.배치입고예정일}}</p>
																							<p v-else style="color:red;">{{c.배치필요입고예정일}}</p>
																						</td>
																						<td>
																							<p v-if="c.배치발주번호">{{c.배치박스번호}}</p>
																							<p v-else>{{c.재고박스번호}}</p>
																						</td>
																						<td>
																							<p v-if="c.배치발주번호">{{c.배치파렛트번호}}</p>
																							<p v-else>{{c.재고파렛트번호}}</p>
																						</td>
																						<!-- <td>action button</td> -->
																					</tr>
																				</tbody>
																				</table>
																			</div> 
																			<div class="mt-4" v-if="showchecklist">
																					<div class="row">
																						<div class="col-md-6 mb-2">
																							<div class="custom-control custom-radio custom-control-inline h-100">
																								<input type="radio" id="customRadio13" name="customRadio-3" class="custom-control-input" v-model="selected3" value="arrangepo" @change="check">
																								<label class="custom-control-label" for="customRadio13" v-b-tooltip.top="'발주내역에 배치'">발주배치 등록/변경</label>
																							</div>
																							<div class="custom-control custom-radio custom-control-inline h-100">
																								<input type="radio" id="customRadio14" name="customRadio-3" class="custom-control-input" v-model="selected3" value="locationchange">
																								<label class="custom-control-label" for="customRadio14" v-b-tooltip.top="'박스변경/위치변경'">재고 변경</label>
																							</div>								
																						</div>
																						<div class="col-md-6 mb-2 float-right">
																							<div class="custom-control custom-control-inline h-100 float-right">
																								<button class="btn btn-secondary float-right" type="button" @click="deleteitem">선택삭제</button>
																							</div>								
																						</div>

																						<!-- 발주배치 -->
																						<div class="col-md-12 mb-2" v-if="selected3=='arrangepo'">
																							<div class="table-responsive" style="text-align:center;vertical-align:middle;">
																								<table id="datatable" class="table data-table table-striped dataTable">
																								<thead style="font-size:80%;">
																									<tr class="ligth ligth-data">
																										

																										<th style="font-size:115%;vertical-align: middle;">발주센터</th> 
																										<th style="font-size:115%;vertical-align: middle;">입고예정일</th>
																										<th style="font-size:115%;vertical-align: middle;">필요수량</th>
																										<th style="font-size:115%;vertical-align: middle;">발주배치</th>
																										<th style="font-size:115%;vertical-align: middle;">배치박스</th>
																										<th style="font-size:115%;vertical-align: middle;">현재배치수량</th>
																										<th style="font-size:115%;vertical-align: middle;">발주번호</th>												
																										
																									</tr>
																								</thead>
																								<tbody style="font-size:100%;">
																									<tr v-for="(d,idx) in dfindlists" :key="idx">

																										<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{d.발주센터}}<span class="ml-2" @click="print('printMe4' + idx)"><i class="ri-printer-line" style="font-size:200%"></i></span>
																											<div class="d-none text-center" style="text-align:center;" :id="'printMe4'+idx">
																												<div class="row mb-0" style="text-align:center;font-size:200%">{{$store.state.user.companyid}}</div>
																												<div class="row mb-0" style="text-align:center;font-size:200%">{{d.발주센터}}</div>
																												<div class="row mb-0" style="text-align:center;font-size:200%">{{d.입고예정일}}</div>
																												<div class="row mb-0" style="text-align:center;font-size:400%">{{d.배치박스번호}}</div>
																												<div class="row mb-0" style="text-align:center;">
																													<barcode :value="$store.state.user.companyid.slice(0,2)+'kr'+d.입고예정일.replaceAll('-','').slice(4)+d.배치박스번호" :tag="bctag" width="2" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
																												</div>
																											</div>
																										</td>
																										
																										<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{d.입고예정일}}</td>
																										<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{d.발주세부내역.필요수량}}</td>
																										<td style="max-width:50px;overflow:hidden;text-overflow: ellipsis;">
																											<button v-if="d.select.length > 0" v-bind:class="buttoncolor(d.select)" type="button" @click="selectarrange(d,idx)">{{d.select}}</button>
																											<!-- <input style="font-size:200%;text-align:center" :min="0" :max="a.발주세부내역.필요수량" type="number" class="form-control" placeholder="배치수량" aria-label="" v-model="qty[i]"> -->
																										</td>
																										<td style="max-width:100px;overflow:hidden;text-overflow: ellipsis;">
																											<!-- <b-button variant="primary" class="mb-1" style="font-size:200%">
																											<b>{{currentboxnumber}}</b> <b-badge variant="light" class="ml-2"><b>{{currentboxqty}}</b></b-badge>
																											</b-button> -->
																											<div style="display: table; margin-left: auto; margin-right: auto; display: inline-block;">
																											<div class="input-group position-relative">
																												<input style="font-size:200%;text-align:center" :min="0" type="number" class="form-control h-100" placeholder="박스번호" aria-label="" @keyup.enter="changebox($event,d)" v-model="d.배치박스번호">
																												<span v-if="d.newbox" class="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-secondary p-2">new</span>
																											</div>
																											<div class="input-group mt-2">
																												<input style="text-align:center" :min="0" type="text" class="form-control" placeholder="박스위치" aria-label="" @keyup.enter="changelocation($event,d)" v-model="d.배치파렛트번호">
																												<div class="input-group-append">
																												<!-- <button class="btn btn-secondary" type="button">배치</button> -->
																												</div>
																											</div>
																											</div>
																											
																										</td>
																										<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{d.발주세부내역.배치수량}}</td>
																										<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{d.발주번호}}</td>													
																										
																									</tr>
																								</tbody>
																								</table>
																							</div>
																						</div>



																						<!-- 재고박스/위치변경 -->
																						<div class="col-md-12 mb-2" v-else>
																							<div class="row mt-3">
																								<div class="col-md-3">	
																									<div class="input-group mb-4">
																										<input type="number" class="form-control" placeholder="박스번호" aria-label="" :min="0" @change="boxchangeref" v-model="stockboxnum" style="text-align:center;">
																										<div class="input-group-append">
																										<b-dropdown class="my-class" variant="primary" type="button" text="리스트">
																											<b-dropdown-item @click="boxnumberchange(e)" v-for="e,j2 in stockboxnumbers" :key="j2">{{e}}</b-dropdown-item>
																										</b-dropdown>
																										</div>
																										<span class="ml-2 text-center align-middle" @click="print('printMe5')"><i class="ri-printer-line" style="font-size:200%"></i></span>
																										<div class="d-none text-center" style="text-align:center;" id="printMe5">
																											<div class="row mb-0" style="text-align:center;font-size:200%">{{$store.state.user.companyid}}</div>
																											<div class="row mb-0" style="text-align:center;font-size:200%">재고</div>
																											<div class="row mb-0" style="text-align:center;font-size:400%">{{stockboxnum}}</div>
																											<div class="row mb-0" style="text-align:center;">
																												<barcode :value="$store.state.user.companyid.slice(0,2)+'kr'+'s'+stockboxnum" :tag="bctag" width="2" height="50" :options="{ lineColor: '#0275d8', text: 'Scan', margin : 0}"></barcode>
																											</div>
																										</div>
																									</div>
																								</div>
																								<div class="col-md-3">	
																									<div class="input-group mb-4">
																										<input type="text" class="form-control" placeholder="박스위치" aria-label="" v-model="stockpalletnum"  style="text-align:center;">
																									</div>
																								</div>
																								<!-- <div class="col-md-12">		
																									<div class="form-group">	
																										<input type="text" ref="barcoderegstock" class="form-control" placeholder="바코드 등록" @keyup.enter="barcoderegstock($event)" v-model="regstock">
																									</div>
																								</div>
																								<div class="table-responsive col-md-12" style="font-size:80%;text-align:center;width:100%">
																									<table id="datatable" class="table data-table table-striped dataTable" >
																										<thead>
																											<tr class="ligth ligth-data">
																												
																												<th style="font-size:115%;vertical-align: middle;">상품정보</th>
																												<th style="font-size:115%;vertical-align: middle;">수량</th>
																												<th>삭제</th>
																											</tr>
																										</thead>
																										<tbody>
																											<tr v-for="stockboxlist in stockboxlists" :key="stockboxlist">
																												<td style="min-width:300px;text-align:left;">
																													<div class="d-flex align-items-center">
																														<img :src="stockboxlist.IMGURL" class="img-fluid rounded avatar-80 mr-3" alt="image">
																														<div>
																														{{stockboxlist.상품명}}
																														<p class="mb-0">바코드 : {{stockboxlist.바코드}}</p>
																														<p class="mb-0">SKU ID : {{stockboxlist.SKUID}}</p>
																														</div>
																													</div>
																												</td>
																												<td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;"><b-form-input :min="0" type="number" v-model.number="stockboxlist.수량" style="font-size:200%;text-align:center;"/></td>
																												<td>
																													<a class="badge bg-warning" data-toggle="tooltip" v-b-tooltip.top title="Delete" href="javascript:void(0)" @click="deletelist(stockboxlist)">
																														<i class="ri-delete-bin-line mr-0"></i>
																													</a> 
																												</td>
																											</tr>
																										</tbody>
																									</table>
																								</div> -->
																								<div class="col-md-12">
																									<button class="btn btn-primary" type="button" @click="apply">적용</button>
																								</div>
																							</div>
																						</div>


																					</div>
																				</div>      
																		</b-modal>
																
															</tr>
														</template>
													</tableList>



												</div>
												<button type="button" class="btn btn-primary mr-2 mb-4" @click="download">다운로드</button>
												<button type="button" class="btn btn-secondary mr-2 mb-4" @click="deletestock">삭제</button>
											</div>
										</div>
									</b-card>
								</b-card-group>

								
							</tab-content-item>





							<!-- <tab-content-item :active="false" id="profile" aria-labelled-by="profile-tab">
								<p>구매내역을 업데이트한 후에 구매한 제품이 입고되면 등록해 주세요</p>
								<form @submit.prevent="submit">
									<div class="row">
										<div class="col-md-2">
											<div class="form-group">
												<label>Barcode</label>
												<input type="text" class="form-control" placeholder="Barcode">
											</div>
										</div>
									</div>
									<button type="submit" class="btn btn-primary mr-2">Add Sale</button>
									<button type="reset" class="btn btn-danger">Reset</button>
								</form>
							</tab-content-item>
							<tab-content-item :active="false" id="contact" aria-labelled-by="contact-tab">
								<p>회송 예정인 재고를 등록해 주세요.</p>
								<form @submit.prevent="submit">
									<div class="row">
										<div class="col-md-2">
											<div class="form-group">
												<label>Barcode</label>
												<input type="text" class="form-control" placeholder="Barcode">
											</div>
										</div>
										<div class="col-md-2">
											<div class="form-group">
												<label>SKU ID</label>
												<input type="text" class="form-control" placeholder="SKU ID">
											</div>
										</div>										
									</div>
									<button type="submit" class="btn btn-primary mr-2">Add Sale</button>
									<button type="reset" class="btn btn-danger">Reset</button>
								</form>								
							</tab-content-item> -->
							<tab-content-item :active="false" id="many" aria-labelled-by="many-tab">
								<p>양식을 다운받아서 작성 후 업로드해주세요 (재고파렛트번호/로케이션번호가 없을 경우 빈칸)</p>

								<form @submit.prevent="submit">
									<div class="row">
										<div class="col-md-12">
											<div class="form-group">
												<label>1.&nbsp;&nbsp;업로드 양식 다운로드</label>
												<div>
												<button type="button" @click="excelDownFunc" class="btn btn-primary mr-2">Download</button>
												</div>
											</div>
										</div>
										<div class="col-md-12">
											<div class="form-group">
												<label  for="Code">2.&nbsp;&nbsp;재고 대량등록 업로드</label>
												<div class="custom-file">
												<input ref="upload" type="file" @change="readstockfile" class="custom-file-input" id="inputGroupFile02">
												<label class="custom-file-label" for="inputGroupFile02">{{uploadstatus}}</label>
												</div>
											</div>
										</div> 
									</div>
								</form>								
							</tab-content-item>							
						</tab-content>





					</div>
				</div>
			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required 	} from 'vuelidate/lib/validators';
import axios from 'axios';
import { mapState } from 'vuex';
import * as XLSX from 'xlsx';
import VueBarcode from 'vue-barcode';


export default {
    name:'AddSale',
      mixins: [validationMixin],
    validations: {
      customer: { required },
      referenceno: { required },
      },
	components: {
		'barcode': VueBarcode
	},
	mounted(){
		this.openstocklist()
	},
    data(){
        return{
			신규박스번호: '',
			selected: 'default',
			selected2: 'notastock',
			selected3: 'arrangepo',
			checkpo: false,

			findlists: [],
			dfindlists: [],
			boxlists: [],
			stockboxlists: [],
			stockarr: [],

			//배치박스(boxlist 정보)

			showfindlist: false,
			showarrangebox: false,
			showstockbox: false,
			stockboxnumbers: [],
			stockchange: false,
			
			barcoderegist : '',
			regstock : '',
			totalqty: 0,


			skuidvalue: '',
			barcodevalue: '',
			stockboxnumstring: '',
			stockboxnum: '',
			stockpalletnum: '',

			bcvalue: '',
			bctag: 'svg',
			bcoptions: {
				lineColor: '#ff7069',
				fontSize: 50,
				font: 'Courier',
				width: 3,
				height: 60,
				marginBottom: 60,
				format: 'MSI',
				background: '#ccffff'
			},

			cbar: '',
			csku: '',
			cbox: '',
			cabox: '',

			stocklists:[],
			showlist:false,
			lochange:false,
			stockstyleon: false,

			modalcheck1: false,
			modalcheck: [],
			stockdetail: [],
			stockdetailinit: {},
			stockdetailinitarr: [],
			selectbutton: "선택제품배치",
			changedcnt: 0,
			showchecklist: false,

			checkbox1: false,
			checkboxlist: [],

			keyofarr: [],
			
			delitems:[],

			uploadstatus: 'File upload',

            option1:[
                  {value: 1, text: 'Test Biller'},
                  ],
                   option2:[
                  {value: 1, text: 'Completed'},
                  {value: 2, text: 'Pending'},
                  ],
                   option3:[
                  {value: 1, text: 'No Text'},
                  {value: 2, text: 'GST @5%'},
                  {value: 2, text: 'VAT @10%'},
                  ],
                   option4:[
                  {value: 1, text: 'Pending'},
                  {value: 2, text: 'Due'},
                  {value: 2, text: 'Paid'},
                  ],
                   customToolbar: [["bold",  "underline","italic","image","code"]]
        }
    },
    methods: {
		barcodegap(index,total){
			if(index < total){
				return 'border:0px solid silver;width:300px;text-align: center;margin-bottom:' + this.바코드간격 + 'px'
			} else {
				return 'border:0px solid silver;width:300px;text-align: center;'
			}
		},
		equal(k1,k2){
			JSON.stringify(k1) === JSON.stringify(k2)
		},
		async print(id) {
			const options = {
				name:'_blank',
				autoClose: true,
			}
			await this.$htmlToPaper(id,options);
		},
		stockstyle(i2){
			if(this.stockstyleon && i2 == 0){
				return "font-size:200%;text-align:center;background:#fcc2b1;color:red;font-weight:bold"
			} else {
				return "font-size:200%;text-align:center;"
			}
		},
		changebox(event,b){
			//b: dfindlist element
			if(event.keyCode === 13){
				this.stockdetail.forEach(e => {
					if(e.checked){
						e.배치박스번호 = b.배치박스번호;
					}
				})	
			}
		},
		changelocation(event,b){
			//b: dfindlist element
			if(event.keyCode === 13){
				this.stockdetail.forEach(e => {
					if(e.checked){
						e.배치파렛트번호 = b.배치파렛트번호;
					}
				})	
			}
		},
		deletestockdb(arr){
			axios.post('/api/deletestock',arr)
			.then((res) => {
				if(res.data.요청결과 == "삭제완료"){
					// console.log(res.data.result)
				} else {
					alert("삭제실패")
					console.log(res.data)
				}
			})	
			.catch((e) => {alert("업데이트 실패");console.log(e);})
		},
		deletestock(){
			if(this.checkboxlist.filter(e => e == true).length == 0){
				alert("삭제할 제품을 선택해 주세요")
				return
			}
			if(confirm("선택하신 재고를 삭제하시겠습니까?") == true){
				this.showlist = false;
				var arr = [];
				var ar = [];
				var dellist = [];
				this.checkboxlist.forEach((e,i) => {
					if(!e){
						arr.push(this.stocklists[i])
					} else {
						dellist.push(this.stocklists[i])
					}
				})
				this.stocklists = arr;
				for(var i = 0;i<this.checkboxlist.length;i++){
					this.checkboxlist[i] = false;
				}
				// console.log(dellist)
				setTimeout(() => {
					dellist.forEach(e => {
						e.배치필요내역.forEach(ele => {
							ar.push(ele)
						})
					})
					// console.log(ar)
					// console.log(this.checkboxlist.length, this.stocklists.length, dellist)
					this.deletestockdb(ar)
					this.$store.commit('stockdelete',ar);


					this.showlist = true;
				}, 1);

			} else {
				return false
			}

		},
		checkboxlistfunc(){
			this.checkbox1 = false;
		},
		checkallbox(){
			if(this.checkbox1){
				for(var i = 0;i<this.checkboxlist.length;i++){
					this.checkboxlist[i] = true;
				}
			} else {
				for(i = 0;i<this.checkboxlist.length;i++){
					this.checkboxlist[i] = false;
				}
			}
			// console.log(this.checkboxlist)
		},
		buttoncolor(selectbutton){
			if(selectbutton == '선택제품배치'){
				return "btn btn-success"
			} else {
				return "btn btn-secondary"
			}
		},
		modaldata(stocklist){
			this.stockdetail = [];
			this.stockdetailinit = {};
			this.stockdetailinitarr = [];
			var changedpo = false;
			for (var i=0;i<stocklist.배치필요내역.length;i++){
				if(stocklist.배치발주번호){
					changedpo = true;
				}
				var e = {
					companyid:stocklist.companyid,
					_id: stocklist.배치필요내역[i]._id,
					stockinfo:stocklist.stockinfo,
					등록이미지:stocklist.등록이미지,
					바코드:stocklist.바코드,
					상품명:stocklist.상품명,
					SKUID:stocklist.SKUID,

					배치박스번호:stocklist.배치박스번호,
					배치발주번호:stocklist.배치발주번호,
					배치발주센터:stocklist.배치발주센터,
					배치일시:stocklist.배치일시,
					배치입고예정일:stocklist.배치입고예정일,
					배치파렛트번호:stocklist.배치파렛트번호,
					박스바코드:stocklist.박스바코드,

					재고박스번호:stocklist.재고박스번호,
					재고파렛트번호:stocklist.재고파렛트번호,

					배치필요발주번호:stocklist.배치필요내역[i].배치필요발주번호,
					배치필요발주센터:stocklist.배치필요내역[i].배치필요발주센터,
					배치필요입고예정일:stocklist.배치필요내역[i].배치필요입고예정일,

					checked:false,
					changedpo:changedpo,
					changedstock:false
				}
				this.stockdetail.push(this.deepClone(e))
				this.stockdetailinitarr.push(this.deepClone(e))
				if(this.stockdetailinit.stockinfo === undefined){
					this.stockdetailinit = this.deepClone(e);
				}
				// console.log(this.stockdetail)
			}

			if(this.stockdetail[0].배치필요발주번호 || this.stockdetail[0].배치발주번호){
				this.dfindlistinit(this.stockdetail[0].바코드);
			}
			
			setTimeout(() => {
				this.$nextTick(() => {
					if(this.stockdetailinit.재고박스번호>0){
						this.stockboxnum = parseInt(this.stockdetailinit.재고박스번호);
						this.stockpalletnum = this.stockdetailinit.재고파렛트번호;
					}
				})				
			}, 10);

		},
		dfindlistinit(barcode){
			
			var result = this.openfindlist(barcode)
			// console.log(result)
			if(result.res){
				this.dfindlists = result.findlists;
				this.dfindlists.forEach(ele => {
					ele.select = '선택제품배치';
				})
			} else {
				if(!result.res){
					this.dfindlists = [];
				}
			}
		},
		toggle(a){
			a.checked = !a.checked;
		},
		cancel(){
			this.$nextTick(() => {
				this.stockdetail = [];
				this.stockdetailinit = {};
				this.stockdetailinitarr = [];
				this.showchecklist = false;
				this.modalcheck1 = false;
				// this.$bvModal.hide('modal');
			})
		},
		handleOk() {
			// bvModalEvent.preventDefault();
			this.handleSubmit();
		},
		handleSubmit() {
			var stt = false;
			this.stockdetail.forEach(e => {
				if(e.재고박스번호 == 0 && e.배치박스번호 == 0){
					stt = true;
				}
			})
			if(stt){
				alert('배치취소한 제품은 재고변경에서 박스번호를 지정해 주세요')
			} else {
				var obj = {};
				var arr = [];
				
				if(this.delitems.length > 0){
					axios.post('/api/deletestock',this.delitems)
					.then((res) => {
						if(!res.data.result){
							alert("삭제실패");
							console.log(res)
							return
						}
						this.$store.commit('stockdelete',this.delitems);
						this.delitems = [];
						this.search();
						// console.log(res)
					})	
					.catch((e) => {alert("삭제실패");console.log(e)})
				}



				this.stockdetail.forEach((e,i) => {
					if(!this.objequal(this.stockdetail[i], this.stockdetailinitarr[i])){
						obj = {
							companyid : e.companyid,
							_id : e._id,
							재고박스번호: parseInt(e.재고박스번호),
							재고파렛트번호: e.재고파렛트번호,
							배치일시: e.배치일시,
							배치발주번호: e.배치발주번호,
							배치박스번호: parseInt(e.배치박스번호),
							배치파렛트번호: e.배치파렛트번호,
							배치발주센터: e.배치발주센터,
							배치입고예정일: e.배치입고예정일,
							stockinfo: e.stockinfo,
							site:'kr'
						}
						arr.push(obj);
					}
				})
				console.log(arr)
				if(arr.length > 0){
					axios.post('/api/addstock/updatestock',arr)
					.then((res) => {
						if(!res.data){
							alert("등록실패");
							console.log(res)
							return
						}
						alert(res.data.요청결과);
						// console.log(res.data.stock)
						this.$store.commit('stockupdate',res.data.stock);
						this.search();
					})	
					.catch((e) => {alert("등록실패");console.log(e)})
				}

				
				this.cancel();

			}
			
		},
		apply(){
			if(this.stockdetailinit.stockinfo == "Astock"){
				this.stockdetail.forEach(e => {
					if(e.checked){
						e.stockinfo = "stock";

						e.배치발주번호 = "";
						e.배치발주센터 = "";
						e.배치입고예정일 = "";
						e.배치일시 = "";
						e.배치박스번호 = 0;
						e.배치파렛트번호 = "";
						
						e.재고박스번호 = parseInt(this.stockboxnum);
						e.재고파렛트번호 = this.stockpalletnum;
						
						e.배치필요발주번호 = this.stockdetailinit.배치발주번호;
						e.배치필요발주센터 = this.stockdetailinit.배치발주센터;
						e.배치필요입고예정일 = this.stockdetailinit.배치입고예정일;

						e.changedstock = true;
						e.changedpo = false;
					}
				})						
			} else {
				this.stockdetail.forEach(e => {
					if(e.checked){
						if(e.stockinfo != 'stock' || e.재고박스번호 != this.stockboxnum || e.재고파렛트번호 != this.stockpalletnum){
							e.stockinfo = 'stock';

							e.배치발주번호 = '';
							e.배치발주센터 = '';
							e.배치입고예정일 = '';
							e.배치일시 = '';
							e.배치박스번호 = 0;
							e.배치파렛트번호 = '';
							
							e.재고박스번호 = parseInt(this.stockboxnum);
							e.재고파렛트번호 = this.stockpalletnum;

							e.배치필요발주번호 = this.stockdetailinit.배치필요발주번호;
							e.배치필요발주센터 = this.stockdetailinit.배치필요발주센터;
							e.배치필요입고예정일 = this.stockdetailinit.배치필요입고예정일;
						
							e.changedstock = true;
							e.changedpo = false;
						}
					}
				})
			}
		},
		selectarrange(a,i){
			// console.log(a)
			// console.log(this.stockdetail)
			// console.log(this.stockdetailinit)
			this.checkcnt = this.stockdetail.filter(e => e.checked).length;
			
			if(a.select == '선택제품배치'){
				if(this.stockdetail.filter(e => e.checked && e.stockinfo == 'Astock').length > 0){
					alert('이미 발주 배치된 제품이 포함되어 있습니다.')
				} else {
					//a : dfindlist element
					var now = this.now()
					if(a.발주세부내역.필요수량 >= this.checkcnt){
						a.발주세부내역.배치수량 = a.발주세부내역.배치수량 + this.checkcnt;
						a.발주세부내역.필요수량 = a.발주세부내역.필요수량 - this.checkcnt;
						
						this.stockdetail.forEach(e => {
							if(e.checked){
								e.stockinfo = 'Astock';

								e.배치발주번호 = a.발주번호;
								e.배치발주센터 = a.발주센터;
								e.배치입고예정일 = a.입고예정일;
								e.배치일시 = now;
								e.배치박스번호 = parseInt(a.배치박스번호);
								e.배치파렛트번호 = a.배치파렛트번호;
								
								e.재고박스번호 = 0;
								e.재고파렛트번호 = '';
								
								e.배치필요발주번호 = '';
								e.배치필요발주센터 = '';
								e.배치필요입고예정일 = '';

								e.changedpo = true;
								e.changedstock = false;
							}
						})



						this.dfindlists.forEach((e,index) => {
							if(i == index){
								e.select = '배치취소';
							} else {
								e.select = '';
							}
						})
						
					} else {
						if(confirm('해당 발주서의 필요수량보다 많습니다. 필요수량만큼 배치하시겠습니까?')){
							
							this.modalcheck1 = false;
							var cnt = 0
							this.stockdetail.forEach(e => {
								if(cnt < a.발주세부내역.필요수량){
									if(e.checked){
										e.checked = true;
										e.stockinfo = 'Astock';

										e.배치발주번호 = a.발주번호;
										e.배치발주센터 = a.발주센터;
										e.배치입고예정일 = a.입고예정일;
										e.배치일시 = now;
										e.배치박스번호 = parseInt(a.배치박스번호);
										e.배치파렛트번호 = a.배치파렛트번호;
										
										e.재고박스번호 = 0;
										e.재고파렛트번호 = '';
										
										e.배치필요발주번호 = '';
										e.배치필요발주센터 = '';
										e.배치필요입고예정일 = '';

										e.changedpo = true;
										e.changedstock = false;
									}
								} else {
									e.checked = false;
								}
								cnt++
							})
							this.dfindlists.forEach((e,index) => {
								if(i == index){
									e.select = '배치취소';
								} else {
									e.select = '';
								}
							})
							a.발주세부내역.배치수량 = a.발주세부내역.필요수량;
							a.발주세부내역.필요수량 = 0;
						}
					}
				}

				
			} else {
				if(a.select == '배치취소'){
					if(a.발주세부내역.배치수량 >= this.checkcnt){
						a.발주세부내역.배치수량 = a.발주세부내역.배치수량 - this.checkcnt;
						a.발주세부내역.필요수량 = a.발주세부내역.필요수량 + this.checkcnt;
						if(this.stockdetailinit.stockinfo == "Astock"){
							this.stockdetail.forEach(e => {
								if(e.checked){
									e.stockinfo = "stock";

									e.배치발주번호 = "";
									e.배치발주센터 = "";
									e.배치입고예정일 = "";
									e.배치일시 = "";
									e.배치박스번호 = 0;
									e.배치파렛트번호 = "";
									
									e.재고박스번호 = 0;
									e.재고파렛트번호 = "";
									
									e.배치필요발주번호 = this.stockdetailinit.배치발주번호;
									e.배치필요발주센터 = this.stockdetailinit.배치발주센터;
									e.배치필요입고예정일 = this.stockdetailinit.배치입고예정일;

									e.changedpo = false;
									e.changedstock = false;
								}
							})						
						} else {
							this.stockdetail.forEach(e => {
								if(e.checked){
									e.stockinfo = this.stockdetailinit.stockinfo;

									e.배치발주번호 = this.stockdetailinit.배치발주번호;
									e.배치발주센터 = this.stockdetailinit.배치발주센터;
									e.배치입고예정일 = this.stockdetailinit.배치입고예정일;
									e.배치일시 = this.stockdetailinit.배치일시;
									e.배치박스번호 = parseInt(this.stockdetailinit.배치박스번호);
									e.배치파렛트번호 = this.stockdetailinit.배치파렛트번호;
									
									e.재고박스번호 = parseInt(this.stockdetailinit.재고박스번호);
									e.재고파렛트번호 = this.stockdetailinit.재고파렛트번호;
									
									e.배치필요발주번호 = this.stockdetailinit.배치필요발주번호;
									e.배치필요발주센터 = this.stockdetailinit.배치필요발주센터;
									e.배치필요입고예정일 = this.stockdetailinit.배치필요입고예정일;

									e.changedpo = false;
									e.changedstock = false;
								}
							})
						}
						
						this.dfindlists.forEach(e => {
							e.select = '선택제품배치'
						})
					} else {
						alert('배치취소할 제품 수량을 확인해주세요.')
					}
				}
			}
		},
		checkall(){
			this.showchecklist = false;
			if(this.modalcheck1){
				this.stockdetail.forEach(e => {
					e.checked = true;
				})
				this.check()
			} else {
				this.stockdetail.forEach(e => {
					e.checked = false;
				})
			}
		},
		check(){
			// console.log(this.stockdetail)
			this.showchecklist = false;
			if(this.selected3 == 'arrangepo'){
				if(this.stockdetail.filter(e => e.checked).length > 0){
					if(this.stockdetail.filter(e => e.checked && e.changedpo && e.배치발주번호 ==  this.stockdetail.filter(ele => ele.checked)[0].배치발주번호).length == this.stockdetail.filter(e => e.checked).length){
						this.dfindlistinit(this.stockdetail[0].바코드);
						setTimeout(() => {
							this.stockdetail.forEach(e => {
								this.dfindlists.forEach(ele => {
									if(ele.발주번호 == e.배치발주번호 && this.stockdetailinit.stockinfo == "stock"){
										ele.발주세부내역.배치수량++;
										ele.발주세부내역.필요수량--;
									}
									if(ele.발주번호 == this.stockdetail.filter(element => element.checked)[0].배치발주번호){
										ele.select = '배치취소';
									} else {
										ele.select = '';
									}
								})
							})
						}, 5);
					} else {
						if(this.stockdetail.filter(e => e.checked && e.changedpo).length < this.stockdetail.filter(e => e.checked).length && this.stockdetail.filter(e => e.checked && e.changedpo).length > 0){
							alert("동일 상태의 상품만 동시 선택이 가능합니다.a")
							setTimeout(() => {
								this.stockdetail.forEach(e => {
									e.checked = false;
								})				
								this.modalcheck1 = false;				
							}, 10);

						} else {
							if(this.stockdetail.filter(e => e.changedpo).length > 0){
								if(this.stockdetail.filter(e => e.checked && e.배치발주번호 == this.stockdetail.filter(ele => ele.checked)[0].배치발주번호).length < this.stockdetail.filter(e => e.checked).length){
									alert("동일 상태의 상품만 동시 선택이 가능합니다.b")
									setTimeout(() => {
										this.stockdetail.forEach(e => {
											e.checked = false;
										})
										this.modalcheck1 = false;								
									}, 10);		
								} else {
									//dfindlist 배치수량/필요수량 조정
									this.dfindlistinit(this.stockdetail[0].바코드);
									this.stockdetail.forEach(e => {
										if(e.changedpo){
											setTimeout(() => {
												this.dfindlists.forEach(ele => {
													if(ele.발주번호 == e.배치발주번호 && this.stockdetailinit.stockinfo == "stock"){
														ele.발주세부내역.배치수량++;
														ele.발주세부내역.필요수량--;
													}
												})
											}, 5);
										}
									})
								}
								
							} else {
								this.dfindlistinit(this.stockdetail[0].바코드);
								this.dfindlists = this.dfindlists.filter(e => e.발주세부내역.필요수량 > 0)
							}
						}
					}
				}
				setTimeout(() => {
					if(this.stockdetail.filter(e => e.checked).length == 0){
						this.showchecklist = false;
					} else {
						if(this.dfindlists.length > 0){
							this.showchecklist = true;
						} else {
							alert('배치할 발주내역이 없습니다.')
							this.selected3 = 'locationchange';
							this.showchecklist = true;
						}
					}
				}, 10);
			} else {
				if(this.stockdetail.filter(e => e.checked).length > 0){
					this.showchecklist = true;
				} else {
					this.showchecklist = false;
				}
			}
			// console.log(this.dfindlists)
		},
		search(){
			if(this.$store.state.stock.filter(e => e.site == 'kr').length == 0){
				alert('등록된 재고가 없습니다.')
				return
			}
			this.showfindlist = false;
			this.showstockbox = false;
			this.checkboxlist = [];
			this.openstocklist();
			if(this.selected2 == 'notastock'){
				this.notastocks()
			}
			if(this.selected2 == 'stock'){
				this.stocks()
			}
			if(this.selected2 == 'astock'){
				this.astocks()
			}
			if(this.selected2 == 'stocktotal'){
				this.stocktotals()
			}
		},
		notastocks(){
			this.showlist = false;
			this.checkboxlist = [];
			this.stocklists = [];
			this.parsestock();
			var arr = [];
			setTimeout(() => {
				if(this.stocklists.length > 0){
					this.stocklists.forEach(e => {
						if(e.stockinfo == "stock") {
							if(e.배치필요내역[0].배치필요발주번호 != ''){
								arr.push(e)
							}
						}	
					})
					this.stocklists = arr;
					for(var i = 0;i<this.stocklists.length;i++){
						this.checkboxlist.push(false)
					}
					this.showlist = true;
				}
			}, 50);
		},
		stocks(){
			this.showlist = false;
			this.checkboxlist = [];
			this.stocklists = [];
			this.parsestock();
			var arr = [];
			setTimeout(() => {
				if(this.stocklists.length > 0){
					this.stocklists.forEach(e => {
						if(e.stockinfo == "stock") {
							if(e.배치필요내역[0].배치필요발주번호 == ''){
								arr.push(e)
							}
						}	
					})
					this.stocklists = arr;
					for(var i = 0;i<this.stocklists.length;i++){
						this.checkboxlist.push(false)
					}
					this.showlist = true;
				}
			}, 50);
		},	
		astocks(){
			this.showlist = false;
			this.checkboxlist = [];
			this.stocklists = [];
			this.parsestock();
			var arr = [];
			setTimeout(() => {
				if(this.stocklists.length > 0){
					this.stocklists.forEach(e => {
						if(e.stockinfo == "Astock") {
							arr.push(e)
						}	
					})
					this.stocklists = arr;
					for(var i = 0;i<this.stocklists.length;i++){
						this.checkboxlist.push(false)
					}
					this.showlist = true;
				}
			}, 50);
		},				
		stocktotals(){
			this.showlist = false;
			this.checkboxlist = [];
			this.stocklists = [];
			this.parsestock()
			setTimeout(() => {
				if(this.stocklists.length > 0){
					for(var i = 0;i<this.stocklists.length;i++){
						this.checkboxlist.push(false)
					}
					// console.log(this.stocklists)
					// console.log(this.checkboxlist)
					this.showlist = true;			
				}
			}, 50);
		},
		parsestock(){
			this.stocklists = [];
			var arr = [];
			var obj = {};
			this.$store.state.stock.filter(e => e.site == 'kr').forEach(e => {
				arr.push(this.deepClone(e))
			})
			if(this.cbox.length > 0){
				arr = [];
				this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.재고박스번호 == this.cbox).forEach(e => {
					arr.push(this.deepClone(e))
				})
			}
			if(this.cabox.length > 0){
				arr = [];
				this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.배치박스번호 == this.cabox).forEach(e => {
					arr.push(this.deepClone(e))
				})
			}
			if(this.cbar.length > 0){
				arr = [];
				this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.바코드 == this.cbar).forEach(e => {
					arr.push(this.deepClone(e))
				})
			}
			if(this.csku.length > 0){
				arr = [];
				this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.SKUID == this.csku).forEach(e => {
					arr.push(this.deepClone(e))
				})
			}
			if(arr.length == 0){
				return false;
			}

			arr.forEach(e => {
				obj = this.deepClone(e);
				var inc = false;
				this.stocklists.forEach(ele => {
					if(this.objequal(ele,obj)){
						ele.수량++;
						ele.배치필요내역.push({
							_id:e._id,
							배치필요발주번호:'',
							배치필요입고예정일:'',
							배치필요발주센터:'',
							상품명:e.상품명,
							바코드:e.바코드,
							SKUID:e.SKUID
						});
						inc = true;
					}
				})
				if(!inc){
					obj.배치필요내역 = [];
					obj.배치필요내역.push({
						_id:e._id,
						배치필요발주번호:'',
						배치필요입고예정일:'',
						배치필요발주센터:'',
						상품명:e.상품명,
						바코드:e.바코드,
						SKUID:e.SKUID
					})
					obj.수량 = 1;
					this.stocklists.push(obj);
				}

			})
			this.stocklists.sort(function(a,b){
				if (a.배치박스번호 < b.배치박스번호){
					return 1;
				}
				if (a.배치박스번호 > b.배치박스번호){
					return -1;
				}
				return 0;
			})	
			this.stocklists.sort(function(a,b){
				if (a.재고박스번호 < b.재고박스번호){
					return 1;
				}
				if (a.재고박스번호 > b.재고박스번호){
					return -1;
				}
				return 0;
			})
			this.stocklists.sort(function(a,b){
				if (a.배치박스번호 < b.배치박스번호){
					return 1;
				}
				if (a.배치박스번호 > b.배치박스번호){
					return -1;
				}
				return 0;
			})	

			this.stocklists.sort(function(a,b){
				if (a.바코드 < b.바코드){
					return 1;
				}
				if (a.바코드 > b.바코드){
					return -1;
				}
				return 0;
			})

			this.stocklists.sort(function(a,b){
				if (a.stockinfo < b.stockinfo){
					return -1;
				}
				if (a.stockinfo > b.stockinfo){
					return 1;
				}
				return 0;
			})

			var r = [];
			this.stocklists.forEach(e => {
				if(this.$store.state.productdb.find(ele => ele.바코드 == e.바코드) && this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).등록이미지){
					e.등록이미지 = this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).등록이미지;
				} else {
					e.등록이미지 = '';
				}
				
				// e.check = false;

				if(e.stockinfo == "Astock"){
					e.배치수량 = e.수량;
					e.박스바코드 = this.$store.state.user.companyid.slice(0,2)+'kr'+e.배치입고예정일.replaceAll("-","").slice(4)+e.배치박스번호;
				} else {
					e.배치수량 = 0;
					e.박스바코드 = this.$store.state.user.companyid.slice(0,2)+'kr'+'s'+e.재고박스번호;
				}

				if(e.stockinfo == 'stock'){
					if(r.length == 0 || !r.find(ele => ele.발주세부내역.바코드 == e.바코드)){
						if(e.바코드){
							var result = this.openfindlist(e.바코드)
							if(result.res){
								r = r.concat(result.findlists.filter(e => e.발주세부내역.필요수량 > 0))
							}
						}
					}
				}
			})
			
			var cnt = 0;
			var remain = 0;
			r.forEach(e => {
				remain = e.발주세부내역.필요수량;
				this.stocklists.filter(ele => ele.stockinfo == 'stock' && e.발주세부내역.바코드 == ele.바코드).forEach(ele => {
					ele.배치필요내역.filter(element => element.배치필요발주번호 == '').some(element => {
						if(remain > 0){
							element.배치필요발주번호 = e.발주번호;
							element.배치필요입고예정일 = e.입고예정일;
							element.배치필요발주센터 = e.발주센터;
							remain--
							cnt++
						} else {
							return true
						}
					})
					if(ele.배치필요수량){
						ele.배치필요수량 += cnt;
						cnt = 0;
					} else {
						ele.배치필요수량 = cnt;
						cnt = 0;
					}
				})
			})


			// console.log(this.stocklists)
		},
		modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
		modalID1(index) {
            // console.log(index);
            return 'modal1' + index
        },
		showbox(){
			if (this.selected == 'default') {
				this.$refs.findbarcode.focus();	
				if (this.boxlists.length > 0) {
					this.showarrangebox = true;
					this.showfindlist = false;
					this.showstockbox = false;
				} else {
					this.showarrangebox = false;
					this.showfindlist = false;
					this.showstockbox = false;					
				}
			} else {
				setTimeout(() => {
					if(this.showstockbox){
						this.$refs.barcoderegstock.focus();	
					}
				}, 10);
				this.showstockbox = true;
				this.showfindlist = false;
				setTimeout(() => {
					this.boxchange();
					this.showarrangebox = false;
					this.showfindlist = false;					
				}, 1);
			}
		},
		objequal(object1, object2) {
			var keys1 = ['바코드','재고박스번호','재고파렛트번호','배치발주번호','배치박스번호','배치파렛트번호','stockinfo']

			for (let key of keys1) {
				if (object1[key] !== object2[key]) {
				return false;
				}
			}
			return true;
		},
		findbarcodeinfo(event){
			this.selected = 'default';
			if(event.keyCode === 13){
				// console.log(event.target.value);
				this.showlist = false;
				var result = this.openfindlist(event.target.value);
				console.log(result)
				if(result.res && result.findlists.length > result.findlists.filter(e => e.발주세부내역.필요수량 == 0).length ){
					this.findlists = result.findlists.filter(a => a.발주세부내역.필요수량 > 0);
					this.showfindlist = true;
					this.showstockbox = false;
				} else {
					// if(!result.res){
						alert("해당 바코드의 추가 발주내역이 없습니다.")
						this.findlists = [];
						this.showfindlist = false;
						this.showstockbox = false;
						this.selected = 'reg'
						this.showbox()
					// }
				}
			}
		},
		findskuinfo(event){
			//작성필요
			this.selected = 'default';
			if(event.keyCode === 13){
				// console.log(event.target.value)
				this.showlist = false;
				var barcode = this.productdb.find(e => e["SKU ID"] == event.target.value).바코드;
				var result = this.openfindlist(barcode);
				if(result.res && result.findlists.length > result.findlists.filter(e => e.발주세부내역.필요수량 == 0).length ){
					this.findlists = result.findlists.filter(e => e.발주세부내역.필요수량 > 0);
					this.showfindlist = true;
					this.showstockbox = false;
				} else {
					// if(!result.res){
						alert("해당 SKU의 추가 발주내역이 없습니다.")
						this.findlists = [];
						this.showfindlist = false;
						this.showstockbox = false;
						this.selected = 'reg'
						this.showbox()
					// }
				}
			}
		},
		arrangeboxchange(event){
			this.selected = 'default';
			if(event.keyCode === 13){
				// console.log(event.target.value);
				this.showlist = false;
				var result = this.openfindlist(event.target.value);
				if(result.res && result.findlists.length > result.findlists.filter(e => e.발주세부내역.필요수량 == 0).length ){
					this.findlists = result.findlists.filter(e => e.발주세부내역.필요수량 > 0);
					this.showfindlist = true;
					this.showstockbox = false;
				} else {
					// if(!result.res){
						alert("해당 바코드의 추가 발주내역이 없습니다.")
						this.findlists = [];
						this.showfindlist = false;
						this.showstockbox = false;
						this.selected = 'reg'
						this.showbox()
					// }
				}
			}
		},
		arrangelocation(event){
			this.selected = 'default';
			if(event.keyCode === 13){
				// console.log(event.target.value);
				this.showlist = false;
				var result = this.openfindlist(event.target.value);
				if(result.res && result.findlists.length > result.findlists.filter(e => e.발주세부내역.필요수량 == 0).length ){
					this.findlists = result.findlists.filter(e => e.발주세부내역.필요수량 > 0);
					this.showfindlist = true;
					this.showstockbox = false;
				} else {
					// if(!result.res){
						alert("해당 바코드의 추가 발주내역이 없습니다.")
						this.findlists = [];
						this.showfindlist = false;
						this.showstockbox = false;
						this.selected = 'reg'
						this.showbox()
					// }
				}
			}
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
		maxvalue(data,key){
			return data.reduce((prev, current) => (prev[key] > current[key]) ? prev : current)[key]
		},
		changestockinfo(stocklist,stockinfo){
			if(stockinfo == "stock") {
				if(stocklist.배치필요내역[0].배치필요발주번호 == ''){
					return "재고"
				} else {
					return "배치필요"
				}
			}
			if(stockinfo == "Astock") {
			return "배치재고"
			}
			if(stockinfo == "Pstock") {
			return "구매재고"
			}
			if(stockinfo == "Rstock") {
			return "회송재고"
			}                      
		},
		changeboxinfo(stockinfo,재고박스번호,배치박스번호){
			if(stockinfo == "stock") {
			return 재고박스번호
			}
			if(stockinfo == "Astock") {
			return 배치박스번호
			}
			if(stockinfo == "Pstock") {
			return "구매입고전"
			}
			if(stockinfo == "Rstock") {
			return "회송입고전"
			} 
		},
		changepalletinfo(stockinfo,재고파렛트번호,배치파렛트번호) {
			if(stockinfo == "stock") {
			return 재고파렛트번호
			}
			if(stockinfo == "Astock") {
			return 배치파렛트번호
			}
			if(stockinfo == "Pstock") {
			return "구매입고전"
			}
			if(stockinfo == "Rstock") {
			return "회송입고전"
			}            
		},
		badgecolor(stocklist,stockinfo){
			if(stockinfo == "stock") {
				if(stocklist.배치필요내역[0].배치필요발주번호 == ''){
					return "badge badge-primary"
				} else {
					return "badge badge-secondary"
				}
			}
			if(stockinfo == "Astock") {
			return "badge badge-success"
			}
			if(stockinfo == "Pstock") {
			return "badge badge-warning"
			}
			if(stockinfo == "Rstock") {
			return "badge badge-danger"
			} 
		},
		openfindlist(barcode){
			var arrangecnt = 0;
			var notarrangecnt = 0;
			var pstockcnt = 0;
			var rstockcnt = 0;
			const bar = barcode;
			if(this.productdb.find(e => e.바코드 == bar) === undefined){
				alert("존재하지 않는 바코드 입니다")
			} else {
				// if(this.selected == 'default') { //발주서선택 등록
					//바코드 찾는 함수
					var result = [];
					var temp = {};
					this.$store.state.polist.forEach((e) => {
						if(!e.발주상태.includes("거래명세서") && !e.발주상태.includes("발주취소")) {
							e.발주세부내역.some((ele) => {
								if(this.$store.state.exportlist.length > 0){
									if(this.$store.state.exportlist.filter(element => element.발주번호 == e.발주번호 && element.바코드 == ele.바코드).length > 0){
										return
									}
								}
									if(ele.바코드 == bar){
										arrangecnt = 0;
										notarrangecnt = 0;
										pstockcnt = 0;
										rstockcnt = 0;
										if (this.$store.state.stock.length != 0){
											this.$store.state.stock.forEach(element => {
												if(element.바코드 == ele.바코드){
													if(element.배치발주번호 == e.발주번호){
														arrangecnt++
													}
												}
											})
										}
										ele.발주수량 = parseInt(ele.발주수량);
										ele.입고수량 = parseInt(ele.입고수량);
										if(isNaN(ele.입고수량)){
											ele.입고수량 = 0;
										}
										ele.확정수량 = parseInt(ele.확정수량);
										ele.배치수량 = arrangecnt;
										ele.미배치재고수량 = notarrangecnt;
										ele.구매완료수량 = pstockcnt;
										ele.회송예정수량 = rstockcnt;
										ele.사입재고수량 = 0;
										if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
											this.$store.state.productdb.some(element => {
												if(ele.바코드 == element.바코드 && element.구매링크 == '사입'){
													ele.사입재고수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt;
													return (ele.바코드 == element.바코드 && element.구매링크 == '사입')
												}
											})
										}
										ele.구매필요수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt - ele.사입재고수량;
										ele.필요수량 = ele.확정수량 - ele.배치수량


										// if(ele.필요수량 > 0){
											temp = this.deepClone(e);
											temp.발주세부내역 = ele;
											result.push(temp);
											temp = {};
											return (ele.바코드 == bar && ele.필요수량 > 0)
										// }
									}
								
							});
						}
					})
					//발주센터로 먼저 정렬
					result.sort(function(a,b){
						if (a.발주센터 < b.발주센터){
							return -1;
						}
						if (a.발주센터 > b.발주센터){
							return 1;
						}
						return 0;
					})
					//입고예정일순으로 최종 정렬
					result.sort(function(a,b){
						return new Date(a.입고예정일) - new Date(b.입고예정일);
					})
					// console.log(result)
					// console.log(this.$store.state.stock.length)
					if(this.$store.state.stock.filter(e => e.site == 'kr').length > 0){
						this.신규박스번호 = this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.stockinfo == "Astock").reduce((acc, shot) => acc = acc > shot.배치박스번호 ? acc : shot.배치박스번호, 0) + 1;
					} else {
						this.신규박스번호 = 1;
					}


					result.forEach(a => {
						a.추가배치수량 = 0;
						if(this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.배치발주센터 == a.발주센터 && e.배치입고예정일 == a.입고예정일).length == 0){
							if(this.$store.state.stock.filter(e => e.site == 'kr').length == 0){ 
								a.배치박스번호 = 1;
								a.newbox = true;
								a.배치파렛트번호 = '';
							} else {
								if(this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.stockinfo == "Astock").length == 0){
									a.배치박스번호 = 1;
									a.newbox = true;
									a.배치파렛트번호 = '';
								} else {
									var num = this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.stockinfo == "Astock").reduce((acc, shot) => acc = acc > shot.배치박스번호 ? acc : shot.배치박스번호, 0) + 1;
									a.배치박스번호 = parseInt(num);
									a.newbox = true;
									a.배치파렛트번호 = '';
								}
							}
						} else {
							num = this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.배치발주센터 == a.발주센터 && e.배치입고예정일 == a.입고예정일).reduce((acc, shot) => acc = acc > shot.배치박스번호 ? acc : shot.배치박스번호, 0);
							a.배치박스번호 = parseInt(num);
							a.newbox = false;
							a.배치파렛트번호 = this.$store.state.stock.filter(e => e.site == 'kr').find(e => e.배치박스번호 == num).배치파렛트번호;
						}
						
					})

					if(result.length == 0){
						return {res: false}
					} else {
						// console.log(result)
						return {res: true, findlists : result}
					}
					
				// } else {
				// 	return {res: false}
				// }
			}

		},
		now(){
			var today = new Date();
			today.setHours(today.getHours() + 9); 
			return today.toISOString().replace('T', ' ').substring(0, 19); 
		},
		savearrange(){
			if(this.findlists.length > 0){
				var obj = {}
				var now = this.now()
				var arr = [];
				var j
				this.findlists.forEach((e,i) => {
					if(parseInt(e.추가배치수량) > 0){
						for(j=0;j<parseInt(e.추가배치수량);j++){
							obj = {
								companyid:this.$store.state.user.companyid,
								바코드:this.findlists[i].발주세부내역.바코드,
								상품명:this.findlists[i].발주세부내역.상품명,
								SKUID:this.findlists[i].발주세부내역.SKUID,
								구매id:'',
								회송id:'',
								입고일시: now,
								입고송장번호: '',
								재고박스번호: 0,
								재고파렛트번호: '',
								배치일시: now,
								배치발주번호: this.findlists[i].발주번호,
								배치박스번호: parseInt(this.findlists[i].배치박스번호),
								배치파렛트번호: this.findlists[i].배치파렛트번호,
								배치발주센터: this.findlists[i].발주센터,
								배치입고예정일: this.findlists[i].입고예정일,
								stockinfo: 'Astock',
								선적출고일시:'',
								site:'kr'
							}
							arr.push(obj);
						}
					}
				})
				axios.post('/api/addstock',arr)
				.then((res) => {
					if(!res.data){
						alert("등록실패");
						console.log(res)
					} else {
						this.$store.commit('stockupdate',res.data.stock);
						this.showfindlist = false;
						alert(res.data.요청결과);
						var result = this.openfindlist(this.barcodevalue);
						setTimeout(() => {
							if(result.res){
								this.findlists = result.findlists.filter(e => e.발주세부내역.필요수량 > 0);
								this.showfindlist = true;
								this.showstockbox = false;
							} else {
								if(!result.res){
									alert("해당 바코드의 추가 발주내역이 없습니다.")
									this.findlists = [];
									this.showfindlist = false;
									this.showstockbox = false;
								}
							}
						}, 5);
					}
				})	
				.catch((e) => {alert("등록실패");console.log(e);})
			} else {
				alert("배치 저장할 수량이 없습니다.")
			}
		},
		openstocklist(){
			if (this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.stockinfo == 'stock').length == 0) {
				this.stockboxnum = 1;
				this.stockpalletnum = '';
				this.stockboxlists = [];
				this.stockboxnumbers = [];
			} else {
				var max = this.$store.state.stock.filter(e => e.site == 'kr').reduce((acc, shot) => acc = acc > this.timestamp(shot.입고일시) ? acc : this.timestamp(shot.입고일시), 0);
				this.stockboxnum = this.$store.state.stock.filter(e => e.site == 'kr').find(e => this.timestamp(e.입고일시) == max).재고박스번호;
				this.stockpalletnum = this.$store.state.stock.filter(e => e.site == 'kr').find(e => e.재고박스번호 == this.stockboxnum).재고파렛트번호;
				this.stockboxnumbers = this.$store.state.stock.filter(e => e.site == 'kr').map(item => item.재고박스번호).filter((v,i,a) => a.indexOf(v) === i);
				this.stockboxnumbers.sort(function(a,b){
					if (parseInt(a) < parseInt(b)){
						return -1;
					}
					if (parseInt(a) > parseInt(b)){
						return 1;
					}
					return 0;
				})
			}
		},
		boxnumberchange(number){
			this.stockboxnum = number;
			this.boxchange();
		},		
		stocklocation(event){
			if(event.keyCode === 13){
				// console.log(event.target.value);
				this.savestock();
			}
		},
		boxchangeref(){
			if(this.stockboxnum == '' || this.stockboxnum < 1){
				var max = this.$store.state.stock.filter(e => e.site == 'kr').reduce((acc, shot) => acc = acc > this.timestamp(shot.입고일시) ? acc : this.timestamp(shot.입고일시), 0);
				this.stockboxnum = this.$store.state.stock.filter(e => e.site == 'kr').find(e => this.timestamp(e.입고일시) == max).재고박스번호;
				this.boxchange()
			} else {
				this.boxchange()
			}
		},
		boxchange(){
			this.stockstyleon = false;
			this.stockchange = false;	
			if (this.$store.state.stock.filter(e => e.site == 'kr').length == 0) {
				this.showstockbox = false;
				this.stockboxnum = 1;
				this.stockpalletnum = '';
				this.stockboxlists = [];
				this.showstockbox = true;
			} else {					
				if(this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.stockinfo == 'stock').length == 0){
					this.showstockbox = false;
					this.stockboxnum = 1;
					this.stockpalletnum = '';
					this.stockboxlists = [];
					this.showstockbox = true;
				} else {
					this.showfindlist = false;
					this.showstockbox = false;
					var arr = [];
					var obj = {};
					var cnt = 0;
					var imgurl = '';
					this.stockboxlists = [];
					this.stockarr = [];
					if(this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.stockinfo == 'stock' && e.재고박스번호 == this.stockboxnum).length > 0){
						arr = this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.stockinfo == 'stock' && e.재고박스번호 == this.stockboxnum);
						this.stockarr = arr;
						arr.forEach(e => {
							if(this.productdb.find(ele => ele.바코드 == e.바코드) && this.productdb.find(ele => ele.바코드 == e.바코드).등록이미지){
								imgurl = this.productdb.find(ele => ele.바코드 == e.바코드).등록이미지;
							} else {
								imgurl = '';
							}
							cnt = 0;
							this.stockboxlists.some(ele => {
								if(ele.바코드 == e.바코드){
									ele.수량++
									cnt = 1;
									return (ele.바코드 == e.바코드)
								}
							})
							if (cnt == 0){
								obj = {
									바코드 : e.바코드,
									SKUID : e.SKUID,
									상품명 : e.상품명,
									IMGURL : imgurl,
									수량 : 1
								}
								this.stockboxlists.push(obj);					
							}
						})

						this.totalqty = this.stockboxlists.filter(e => e.수량 > 0).reduce((pv,rv) => { return pv + rv.수량},0)
						// console.log(this.stockboxnum);
						// console.log(this.stockarr)
						// console.log(arr);
						console.log(this.stockboxlists);
						console.log(this.totalqty)
						this.stockpalletnum = this.$store.state.stock.filter(e => e.site == 'kr').find(e => e.재고박스번호 == this.stockboxnum).재고파렛트번호;
					}
					this.showstockbox = true;	
				}
				
			}
		},
		deletearr(arr,i){
			arr.splice(i,1)
			return arr
		},
		addstock(barcode){
			this.stockchange = true;
			if(this.productdb.find(e => e.바코드 == barcode)){
				// console.log(barcode)
				var arr = [];
				var obj = {};
				this.stockboxlists.forEach(e => {
					arr.push(e.바코드);
				})
				var deltarget = 0;
				if(arr.includes(barcode)){
					this.stockboxlists.some((e,i) => {
						if(e.바코드 == barcode){
							e.수량++
							obj = this.deepClone(e);
							deltarget = i
							return (e.바코드 == barcode)
						}
					})
					this.stockboxlists = this.deletearr(this.stockboxlists,deltarget);
					this.stockboxlists.unshift(obj);
					this.stockstyleon = true;

				} else {
					obj = {
						바코드 : barcode,
						SKUID : this.productdb.find(e => e.바코드 == barcode)["SKU ID"],
						상품명 : this.productdb.find(e => e.바코드 == barcode).상품명,
						IMGURL : this.productdb.find(e => e.바코드 == barcode).등록이미지,
						수량 : 1
					}
					// console.log(obj)
					this.stockboxlists.unshift(obj);
					this.stockstyleon = true;
				}
				this.totalqty = this.stockboxlists.filter(e => e.수량 > 0).reduce((pv,rv) => { return pv + rv.수량},0)
				// console.log(this.stockboxlists)
			} else {
				alert('상품 데이터베이스에 없는 바코드 번호 입니다!')
			}
		},
		deletelist(stockboxlist){
			this.stockchange = true;
			var arr = [];
			var proceed = confirm("'" + stockboxlist.상품명 + "'" + '\n' + "위 상품을 삭제하시겠습니까?");
			if(proceed){
				this.stockboxlists.forEach(e => {
					if(e.바코드 != stockboxlist.바코드){
						arr.push(e)
					}
				})
				this.stockboxlists = arr;
			}
			this.totalqty = this.stockboxlists.filter(e => e.수량 > 0).reduce((pv,rv) => { return pv + rv.수량},0)
		},
		deleteitem(){
			this.delitems = this.stockdetail.filter(e => e.checked);
			this.stockdetail = this.stockdetail.filter(e => !e.checked);
			this.showchecklist = false;
			console.log(this.delitems)
		},
		locationchange(){
			this.stockchange = true;
			this.savestock();
			this.stockchange = false;
		},
		async savestock(){
			if(this.stockchange){
				this.showlist = false;
				// console.log(this.stockboxlists)
				// console.log(this.stockarr)
				if(this.stockboxlists.length > 0 && this.stockboxlists.reduce((pv,cv) => {return pv + cv.수량},0) > 0){
					
					var templists = this.stockboxlists;
					var arr = [];
					var obj = {}
					var now = this.now()
					var cnt = 0;
					var j = 0;
					templists.forEach(e => {
						if(this.stockarr.filter(ele => ele.바코드 == e.바코드).length > 0){
							if(e.수량 > this.stockarr.filter(ele => ele.바코드 == e.바코드).length){
								this.stockarr.filter(ele => ele.바코드 == e.바코드).forEach(element => {
									element.재고박스번호 = parseInt(this.stockboxnum);
									element.재고파렛트번호 = this.stockpalletnum;
									arr.push(element)
								})
								cnt = e.수량 - this.stockarr.filter(ele => ele.바코드 == e.바코드).length;
								for(j=0;j<cnt;j++){
									obj = {
										companyid:this.$store.state.user.companyid,
										바코드:e.바코드,
										상품명:e.상품명,
										SKUID:e.SKUID,
										구매id:'',
										회송id:'',
										입고일시: now,
										입고송장번호: '',
										재고박스번호: parseInt(this.stockboxnum),
										재고파렛트번호: this.stockpalletnum,
										배치일시: '',
										배치발주번호: '',
										배치박스번호: 0,
										배치파렛트번호: '',
										배치발주센터: '',
										배치입고예정일: '',
										stockinfo: 'stock',
										선적출고일시:'',
										site:'kr'
									}
									arr.push(obj);
								}
							} else {
								if(e.수량 <= this.stockarr.filter(ele => ele.바코드 == e.바코드).length){
									for(j=0;j<e.수량;j++){
										this.stockarr.filter(ele => ele.바코드 == e.바코드)[j].재고박스번호 = parseInt(this.stockboxnum);
										this.stockarr.filter(ele => ele.바코드 == e.바코드)[j].재고파렛트번호 = this.stockpalletnum;
										arr.push(this.stockarr.filter(ele => ele.바코드 == e.바코드)[j])
									}
								}
							}
						} else {
							//stockarr에 존재하지 않는 바코드일 경우
							if(e.수량 > 0){
								for(j=0;j<e.수량;j++){
									obj = {
										companyid:this.$store.state.user.companyid,
										바코드:e.바코드,
										상품명:e.상품명,
										SKUID:e.SKUID,
										구매id:'',
										회송id:'',
										입고일시: now,
										입고송장번호: '',
										재고박스번호: parseInt(this.stockboxnum),
										재고파렛트번호: this.stockpalletnum,
										배치일시: '',
										배치발주번호: '',
										배치박스번호: 0,
										배치파렛트번호: '',
										배치발주센터: '',
										배치입고예정일: '',
										stockinfo: 'stock',
										선적출고일시:'',
										site:'kr'
									}
									// console.log(this.stockboxnum)
									// console.log(parseInt(this.stockboxnum))
									arr.push(obj);
								}
							}
						}
					})

					arr.forEach(e => {
						if(e._id){
							delete e._id
						}
					})
					// console.log(arr)
					await axios.post('/api/addstock/updatebox',{stock:arr,재고박스번호:parseInt(this.stockboxnum)})
					.then((res) => {
						alert(res.data.요청결과);
						// console.log(res.data)
						this.$store.commit('stockupdate',res.data.stock)
						this.stockboxnumbers = this.$store.state.stock.filter(e => e.site == 'kr').map(item => item.재고박스번호).filter((v,i,a) => a.indexOf(v) === i);
						this.stockboxnumbers.sort(function(a,b){
							if (parseInt(a) < parseInt(b)){
								return -1;
							}
							if (parseInt(a) > parseInt(b)){
								return 1;
							}
							return 0;
						})
						if(!res.data){
							alert("등록실패(2110)");
							console.log(res)
						}
						this.boxchange()
					})	
					.catch((e) => {alert("등록실패(2115)");console.log(e)})
				} else {                                                                              
					var proceed = confirm("저장할 수량이 없습니다. 박스번호를 삭제하시겠습니까?");
					if(proceed){
						arr = [];
						axios.post('/api/addstock/updatebox',{stock:arr,재고박스번호:parseInt(this.stockboxnum),companyid:this.$store.state.user.companyid,site:'kr'})
						.then((res) => {
							alert(res.data.요청결과);
							this.$store.commit('stockupdate',res.data.stock)
							this.stockboxnumbers = this.$store.state.stock.filter(e => e.site == 'kr').map(item => item.재고박스번호).filter((v,i,a) => a.indexOf(v) === i);
							this.stockboxnumbers.sort(function(a,b){
								if (parseInt(a) < parseInt(b)){
									return -1;
								}
								if (parseInt(a) > parseInt(b)){
									return 1;
								}
								return 0;
							})
							this.boxchange()
							if(!res.data){
								alert("등록실패");
								console.log(res)
							}
						})	
						.catch((e) => {alert("등록실패");console.log(e)})
					}
				}
			}
			
		},
		async barcoderegstock(event){ //재고등록
			if(event.keyCode === 13){
				// console.log(event.target.value);
				if(this.checkpo){
					this.showlist = false;
					var result = this.openfindlist(event.target.value);
					console.log(result)
					if(result.res && result.findlists.length > result.findlists.filter(e => e.발주세부내역.필요수량 == 0).length){
						alert('발주내역이 확인되어 저장 후 발주배치로 이동됩니다.')
						await this.savestock();
						this.findlists = result.findlists.filter(e => e.발주세부내역.필요수량 > 0);
						this.showfindlist = true;
						this.showstockbox = false;
						this.selected = 'default';
					} else {
						this.addstock(event.target.value);
						this.regstock = '';
					}
				} else {
					this.addstock(event.target.value);
					this.regstock = '';
				}
			}
		},	
		excelDownFunc(){
            // var arr = JSON.parse(JSON.stringify(this.$store.state.stock.filter(e => e.site == 'kr')))
            // console.log(arr)
            // const myHeader = ["바코드","상품명","SKUID","구매id","회송id","입고일시","입고송장번호","재고박스번호","재고파렛트번호","배치일시","배치발주번호","배치박스번호","배치파렛트번호","배치발주센터","배치입고예정일","stockinfo"];
            // var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});

            // const range = XLSX.utils.decode_range(dataWS['!ref']);
            // range.e['c'] = myHeader.length - 1;
            // dataWS['!ref'] = XLSX.utils.encode_range(range);

            // var wb = XLSX.utils.book_new();

            // XLSX.utils.book_append_sheet(wb, dataWS, 'productDB');
            // // 엑셀 파일을 내보낸다.
            // XLSX.writeFile(wb, '대량재고업로드양식.xlsx');

            var arr = [];
            const myHeader = ["바코드","수량","재고박스번호","박스위치"];
            var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});

            const range = XLSX.utils.decode_range(dataWS['!ref']);
            range.e['c'] = myHeader.length - 1;
            dataWS['!ref'] = XLSX.utils.encode_range(range);

            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, dataWS, '재고업로드');
            // 엑셀 파일을 내보낸다.
            XLSX.writeFile(wb, '대량재고업로드양식.xlsx');


        },
		timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime() / 1000
        },
		getdate(timestamp){
            var date = new Date(timestamp);
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },  
		download() {
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			var arr = []
			if(this.checkboxlist.filter(e => e == true).length == 0 || this.checkboxlist.filter(e => e == false).length == 0){
				arr = this.stocklists;
			} else {
				this.checkboxlist.forEach((e,i) => {
					if(e){
						arr.push(this.stocklists[i])
					}
				})
			}

			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '재고현황')
			XLSX.writeFile(workBook, '재고현황' + '_' + getToday() + '.xlsx')
		},
		readstockfile(event) {
			// console.log("read")
			var obj = {};
			var arr = [];
			var j
			var productname
			var sku
			var now = this.now()
            const file = event.target.files[0];
            var filename = file.name;
            let reader = new FileReader();
            this.uploadstatus = "업로드 중 ...... (업로드가 완료될 때까지 기다려 주세요)";
            reader.onload = (e) => {
                // let data = reader.result;
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});
                workbook.SheetNames.forEach(sheetName => {
                    const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    // console.log(roa)
					roa.forEach(e => {
						this.$store.state.productdb.some(ele => {
							if(ele.바코드 == e.바코드){
								productname = ele.상품명;
								sku = ele["SKU ID"];
								return (ele.바코드 == e.바코드)
							}
						})
						for(j=0;j<e.수량;j++){
							obj = {
								companyid:this.$store.state.user.companyid,
								바코드:e.바코드,
								상품명:productname,
								SKUID:sku,
								구매id:'',
								회송id:'',
								입고일시: now,
								입고송장번호: '',
								재고박스번호: parseInt(e.재고박스번호),
								재고파렛트번호: e.재고파렛트번호,
								배치일시: '',
								배치발주번호: '',
								배치박스번호: 0,
								배치파렛트번호: '',
								배치발주센터: '',
								배치입고예정일: '',
								stockinfo: 'stock',
								선적출고일시:'',
								site:'kr'
							}
							this.$store.commit('stockinsert',obj);
							arr.push(obj);
						}
					})
					axios.post('/api/addstock',arr)
					.then((res) => {
						alert(res.data.요청결과);
						this.$store.commit('stockupdate',res.data.stock)
						if(!res.data){
							alert("등록실패");
							console.log(res)
						}
					})	
					.catch((e) => {alert("등록실패");console.log(e)})

                });
            };
            reader.readAsBinaryString(file);
            this.$refs.upload.value = '';
            this.uploadstatus = filename + " 등록완료 (" + arr.length + "개)";
        },
		astockupdate(){
			// ---------------------------this.boxlists를 astock state와 axios.post(stock)에 업데이트
			var arr = [];
			var payload = {};
			var now = this.now()
			var i;
			// console.log(this.boxlists);
			this.boxlists.forEach(element => {
				// console.log(element)
				var bar = element.바코드;
				var 등록이미지 = this.productdb.find(e => e.바코드 == element.바코드).등록이미지;
				var sku = this.productdb.find(e => e.바코드 == element.바코드)["SKU ID"];
				var 상품명 = this.productdb.find(e => e.바코드 == element.바코드).상품명;
				for(i=0;i<element.배치수량;i++){
					payload = {
						companyid : this.$store.state.user.companyid,
						바코드 : bar,
						상품명 : 상품명,
						SKUID : sku,
						등록이미지 : 등록이미지,
						구매id : '',
						회송id : '',
						입고일시 : now,
						입고송장번호 : '',
						재고박스번호 : 0,
						재고파렛트번호 : '',
						배치일시 : now,
						배치발주번호 : element.발주번호, 
						배치박스번호 : parseInt(element.박스번호),
						배치파렛트번호 : element.파렛트번호,
						배치발주센터 : element.발주센터,
						배치입고예정일 : element.입고예정일,
						stockinfo : 'Astock',
						선적출고일시:'',
						site:'kr'
					};
					arr.push(payload)
				}
			})
			// console.log(arr)
			axios.post('/api/addstock',arr)
			.then((res) => {
				// console.log(arr,res);
				if(!res.data){
					alert("등록실패");
					console.log(res)
				}
			})	
			.catch((e) => {alert("등록실패");console.log(e)})
		},
		stockupdate(){
			//----------------------------this.stocklists를 stock state와 axios.post(stock)에 업데이트
			var arr = [];
			this.stockboxlists.forEach(element => {
				var bar = element.바코드;
				var sku = this.productdb.find(e => e.바코드 == element.바코드)["SKU ID"];
				var 상품명 = this.productdb.find(e => e.바코드 == element.바코드).상품명;
				var now = this.now()
				for(var i=0;i<element.수량;i++){
					var payload = {
						companyid : this.$store.state.user.companyid,
						바코드 : bar,
						상품명 : 상품명,
						SKUID : sku,
						구매id : '',
						회송id : '',
						입고일시 : now,
						입고송장번호 : '',
						재고박스번호 : parseInt(element.박스번호),
						재고파렛트번호 : element.파렛트번호,
						배치일시 : '',
						배치발주번호 : '', 
						배치박스번호 : 0,
						배치파렛트번호 : '',
						배치발주센터 : '',
						배치입고예정일 : '',
						stockinfo : 'stock',
						선적출고일시:'',
						site:'kr'
					};
					arr.push(payload) 
				}
			})
			axios.post('/api/addstock',arr)
			.then((res) => {if(!res.data){alert("등록실패");console.log(res)}})	
			.catch((e) => {alert("등록실패");console.log(e)})

			

		},
        submit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
             this.submitStatus = true
             window.scrollTo({ top: 0, behavior: 'smooth' });
            }else{    
            this.submitStatus = false
             this.$router.push({name: 'sale.listsale'})
            }
    }},
	computed : {
		...mapState({polists : 'polist', stocklist : 'stock', productdb : 'productdb'})
	}
}
</script>
<style>
#quill-container{
    height: 50px;
}
#quill-container .ql-editor{
    min-height: unset;
}
  .my-class .dropdown-menu {
	
    max-height: 300px;
    overflow-y: auto;
  }
  .modal-dialog {
    max-width: 80%;
    position: absolute; 
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
